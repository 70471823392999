const PayTypeH5WxOfficial = 1 //微信公众号h5支付
const PayTypeAppWx = 2 //app微信支付
const PayTypeWxMini = 4 //微信小程序支付
const PayTypeAppAli = 3 //app支付宝支付
const PayTypeBalance = 5 //余额支付
const PayTypeAdmin = 95 //管理后台操作

const PayTypePointsMallAllPoints = 101 //积分商城全额积分兑换


export function PayTypeNum2Str(pay_type) {
    let str = "";
    switch (parseInt(pay_type)) {
        case PayTypeH5WxOfficial:
            str = "微信公众号网页支付"
            break;
        case PayTypeAppWx:
            str = "app微信支付"
            break;
        case PayTypeAppAli:
            str = "app支付宝支付"
            break;
        case PayTypeBalance:
            str = "余额支付"
            break;
        case PayTypeWxMini:
            str = "微信小程序支付"
            break;
        case PayTypeAdmin:
            str = "管理后台操作"
            break;
        case PayTypePointsMallAllPoints:
            str = "全额积分兑换"
            break;
        default:
            str = "未知类型"
            break;
    }
    return str;
}